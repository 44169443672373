import React, { useContext } from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import { CgMouse } from "react-icons/cg";
import Carousel from "react-multi-carousel";
import { FormattedMessage } from "react-intl";
import { GatsbyImage } from "gatsby-plugin-image";
import { render as toPlainText } from "datocms-structured-text-to-plain-text";
import { shorten } from "../../utils/string";
import localeSlug from "../../utils/localeSlug";
import LocaleContext from "../../context/locale";
import Footer from "../Footer";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 1,
  },
};

function Works({ works }) {
  const { langKey } = useContext(LocaleContext);
  const worksInSlider = works
    .filter((work) => work.preview || work.previewImage)
    .slice(0, 4);

  return (
    <>
      <Helmet>
        <body className="bg-white" />
      </Helmet>
      <div className="relative">
        <Carousel
          responsive={responsive}
          className="h-screen"
          swipeable={false}
          draggable={false}
          infinite
          autoPlay
          autoPlaySpeed={6000}
        >
          {worksInSlider.map((work, index) => (
            <div key={index} className="relative h-screen">
              {work.preview && (
                <video
                  className="w-auto h-auto min-w-full min-h-full max-w-none"
                  autoPlay
                  loop
                  muted
                  src={work.preview.url}
                ></video>
              )}

              {work.previewImage && !work.preview && (
                <GatsbyImage
                  image={work.previewImage.gatsbyImageData}
                  className="w-auto h-auto min-w-full min-h-full max-w-none"
                  alt={work.previewImage.alt || ""}
                />
              )}

              <div
                className="absolute inset-0"
                style={{
                  background:
                    "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAHklEQVQYV2NkYGA4wwABz0EEIzaBzVAVYAqkAkUAAM0sBNE5PliqAAAAAElFTkSuQmCC) repeat",
                }}
              />
              <div className="container mx-auto text-white absolute inset-0 flex flex-col items-start justify-center px-9 lg:px-0">
                <h2 className="text-5xl">{work.title}</h2>
                <p className="text-lg font-roboto my-4">{work.brand}</p>
                <Link
                  to={localeSlug(langKey, `/works/${work.slug}`)}
                  className="mt-10 hover:text-gray-900 hover:bg-white transition-all relative w-44 py-3 px-5 text-sm uppercase mr-4 border border-gray-300 rounded-full tracking-wide inline-block text-center"
                >
                  <FormattedMessage id="worksReadCase" />
                </Link>
              </div>
            </div>
          ))}
        </Carousel>
        <div className="absolute bottom-0 text-white w-full text-center mb-3">
          <Link to="#advertising-title">
            <CgMouse className="animate-bounce inline text-4xl mb-3" />
          </Link>
          <div className="font-roboto tracking-wide text-sm">
            <FormattedMessage id="worksScroll" />
          </div>
        </div>
      </div>

      <div className="container mx-auto py-24 px-9 lg:px-0">
        <h2 id="advertising-title" className="text-4xl text-center mb-20">
          <FormattedMessage id="worksTitle" />
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-9">
          {works.map((work) => {
            const { slug, mainImage, title, brand, challenge } = work;
            return (
              <div key={slug}>
                <Link to={localeSlug(langKey, `/works/${slug}`)}>
                  <GatsbyImage image={mainImage.gatsbyImageData} alt={title} />
                </Link>
                <div className="p-5 font-roboto tracking-wide">
                  <div className="text-lg uppercase">
                    <Link to={localeSlug(langKey, `/works/${slug}`)}>
                      {title}
                    </Link>
                  </div>
                  <div className="text-xs text-gray-500 uppercase bg-gray-200 my-2 px-2">
                    {brand}
                  </div>
                  <div className="text-md my-4 text-gray-500">
                    {shorten(toPlainText(challenge))}
                  </div>
                  <div>
                    <Link
                      to={localeSlug(langKey, `/works/${slug}`)}
                      className="text-xs text-gray-500"
                    >
                      <FormattedMessage id="readMore" />
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Works;
